import React from 'react';
import MktSection from 'src/interface/sections/mkt-section';
import ListArticle from 'src/interface/articles/list-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const PricingSectionProjects = ({ className = '' }) => {
  return (
    <MktSection className={`py-8 animate ${className}`}>
      <SectionHeader>
        <Container className='md text-center'>
          <SectionTitle>Projects we love</SectionTitle>
          <SectionText>
            We tend to focus on the following types of projects though we're always looking for what's next.
          </SectionText>
        </Container>
      </SectionHeader>
      <SectionBody>
        <Container className='pt-5'>
          <Row>
            <Col lg={4}>
              <ListArticle
                icon='store'
                title='Marketplaces'
                description="Digital marketplaces like AirBnB or Etsy let anyone become a verified buyer / seller of physical good or services."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='user-friends'
                title='Social Networking'
                description="From likes to messaging or activity feeds, social networking concepts are available in nearly every modern application."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='stream'
                title='Data Aggregation'
                description="Data is everywhere these days. Data aggregation services help to compile, organize and store it in an intelligent fashion."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='brackets-curly'
                title='API Services'
                description="API services make your company's data accessible to third parties, or across platforms, in an organized and secure fashion."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='taxi'
                title='On-Demand'
                description="These days speed is everything and on-demand services are leading the way from Uber to DoorDash and beyond."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='drone-alt'
                title='Internet of Things'
                description="The fusion of physical goods with cloud-based software allows anything to be tracked, monitored, and controlled from anywhere."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='tachometer-alt-fast'
                title='Software as a Service'
                description="Software as a service is disrupting industries far and wide offering targeted solutions that integrate with other popular tools. "
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='browser'
                title='Platform as a Service'
                description="Platforms as a service are changing the way we think about branding moving them toward a network of micro-services as opposed to one property."
                className='mb-5'
              />
            </Col>
            <Col lg={4}>
              <ListArticle
                icon='lightbulb'
                title='Other Ideas'
                description="It's impossible to list every idea but generally speaking we love building things so let's chat and see if it's a fit."
                className='mb-5'
              />
            </Col>
          </Row>
        </Container>
      </SectionBody>
    </MktSection>
  )
}

export default PricingSectionProjects;
