import React from 'react';

import PricingHeader from 'src/components/pricing/pricing-header';
import PricingSectionProjects from 'src/components/pricing/pricing-section-projects';
import PricingSectionKnow from 'src/components/pricing/pricing-section-know';
import PricingSectionGuarantee from 'src/components/pricing/pricing-section-guarantee';
import PricingSectionFaq from 'src/components/pricing/pricing-section-faq';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const PricingPage = () => {

  return (
    <MktRoute title='Mile Hi Labs | Pricing' description='Learn more here.'>
      <MktNav />
      <PricingHeader />
      <main className='mvh-50 py-5'>
        <PricingSectionKnow />
        <PricingSectionProjects />
        <PricingSectionGuarantee />
        <PricingSectionFaq />
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export default PricingPage;
