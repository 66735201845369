import React from 'react';
import RBAccordion from 'react-bootstrap/Accordion';
import { ButtonWrapper } from 'src/interface/basics/buttons';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const Accordion = ({ id, variant, className = '', children }) => (
	<RBAccordion defaultActiveKey={id} variant={variant} className={`accordion ${className}`}>
		{children}
	</RBAccordion>
)

const AccordionToggle = ({ id, title, active, onClick, className = '' }) => (
	<div className={`accordion-toggle ${className}`}>
    <h5 className='accordion-title'>{title}</h5>
		<RBAccordion.Toggle eventKey={id} as={ButtonWrapper} size='xs' onClick={onClick} className={`h-3 w-3 gray-700 ${active ? 'rotate-in-135' : 'rotate-out-135'}`}>
			<FalIcon icon='plus' className='gray'/>
		</RBAccordion.Toggle>
	</div>
)

const AccordionCollapse = ({ id, className = '', children }) => (
	<RBAccordion.Collapse eventKey={id} className={className}>
		<div className='accordion-collapse'>
			{children}
		</div>
	</RBAccordion.Collapse>
)

export { Accordion, AccordionToggle, AccordionCollapse };

// Docs
// https://getbootstrap.com/docs/5.0/components/accordion/
// https://react-bootstrap.github.io/components/accordion/
