import React from 'react';
import { Card, CardHeader, CardBody, CardPreTitle, CardFooter } from 'src/interface/basics/cards';
import { BtnNextLink } from 'src/interface/basics/links';

const PricingCard = ({ pretitle, title, subtitle, className = '', children }) => {

	return (
		<Card className={`pricing-card ${className}`}>
			<CardHeader className='pricing-card-header'>
				<h6 className='card-pretitle primary'>{pretitle}</h6>
				<h1 className='title gray-800 light'>{title}</h1>
				<h5 className='subtitle gray'>{subtitle}</h5>
			</CardHeader>
			<CardBody className='pricing-card-body'>
				{children}
			</CardBody>
			<CardFooter className='pricing-card-footer'>
				<BtnNextLink to='/contact' className='btn-primary btn-lg btn-block'>Contact Us</BtnNextLink>
			</CardFooter>
		</Card>
	)
}

export default PricingCard;
