import React from 'react';
import MktSection from 'src/interface/sections/mkt-section';
import FeatureCard from 'src/interface/cards/feature-card';
import ListArticle from 'src/interface/articles/list-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const PricingSectionKnow = ({ className = '' }) => {
  return (
    <MktSection className={`py-8 animate ${className}`}>
      <SectionHeader>
        <Container className='md text-center'>
          <SectionTitle>Things to Know</SectionTitle>
          <SectionText>
            Despite every project being different, we like to keep some things the same.
            That way, we can focus more of our energy on doing great work.
          </SectionText>
        </Container>
      </SectionHeader>
      <SectionBody>
        <Container className='pt-5'>
          <Row>
            <Col md={6} lg={4}>
              <ListArticle
                icon='rocket'
                title='Project Focus'
                description="We tend to focus on software applications due to our background and skillset though we're happy to consult on other projects."
              />
            </Col>
            <Col md={6} lg={4}>
              <ListArticle
                icon='mountain'
                title='Project Size'
                description="Despite being a small team, we're happy to take a look at projects big or small. Sometimes more cooks in the kitchen doesn't work."
              />
            </Col>
            <Col md={6} lg={4}>
              <ListArticle
                icon='tools'
                title='Tools We Use'
                description="We're fairly opinionated on the tools we use and why. That said, if you need a specific technology just let us know."
              />
            </Col>
            <Col md={6} lg={4}>
              <ListArticle
                icon='handshake'
                title='Outside Collaboration'
                description="While we tend to code most projects independently, we're more than happy to collaborate with your team or another party."
              />
            </Col>
            <Col md={6} lg={4}>
              <ListArticle
                icon='lightbulb'
                title='When to Start'
                description="It's never too early to start the conversation as our perspective could potentially change the way you think about a project."
              />
            </Col>
            <Col md={6} lg={4}>
              <ListArticle
                icon='road'
                title='Project Flexibility'
                description="Things tend to change as projects come together. We're used to it and will work with you to keep everything on schedule."
              />
            </Col>
          </Row>
        </Container>
      </SectionBody>
    </MktSection>
  )
}

export default PricingSectionKnow;
