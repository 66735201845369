import React from 'react';
import MktSection from 'src/interface/sections/mkt-section';
import FeatureCard from 'src/interface/cards/feature-card';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const PricingSectionGuarantee = ({ className = '' }) => {
  return (
    <MktSection className={`py-8 animate ${className}`}>
      <SectionHeader>
        <Container className='md text-center'>
          <SectionTitle>Our Guarantee To You</SectionTitle>
          <SectionText>
            We know the hustle that entrepreneurs and business owners face first-hand.
            As such, we'll always stand by our work and guarantee the following.
          </SectionText>
        </Container>
      </SectionHeader>
      <SectionBody>
        <Container className='pt-5'>
          <Row>
            <Col lg={4}>
              <FeatureCard
                icon='rocket'
                title='Exceptional Performance'
                description="We tend to focus on custom software applications due to our skillset and background. We also appreciate their complexity and dynamic nature."
              />
            </Col>
            <Col lg={4}>
              <FeatureCard
                icon='mountain'
                title='99% Uptime'
                description="Despite being a small team, we tend to think no project is too big or too small since we can scale up (or down) quickly and efficiently."
              />
            </Col>
            <Col lg={4}>
              <FeatureCard
                icon='bug'
                title='Bug-Free Peformance'
                description="At this point, we're fairly opinionated on the tools and methods we use for most projects. If that's a concern, we'll work through it together."
              />
            </Col>
          </Row>
        </Container>
      </SectionBody>
    </MktSection>
  )
}

export default PricingSectionGuarantee;
