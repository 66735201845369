import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Header, HeaderInner, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns, HeaderOverlay } from 'src/interface/basics/headers';
import PricingCard from 'src/interface/cards/pricing-card';

const PricingHeader = () => {

  return (
    <Header className='gradient-primary-accent'>
    	<div className='py-5'>
	      <Container className='md'>
    			<div className='mb-6'>
    				<h2 className='title white text-center'>Pricing built for any project.</h2>
    				<h4 className='text white text-center'>Ensuring everyone's swimming in the same direction, together.</h4>
  				</div>
				</Container>
				<Container>
	        <Row noGutters>
	          <Col lg={4}>
	            <PricingCard
	              pretitle='Typical Timeline'
	              title='10-20'
	              subtitle='weeks'
	              className='left'>
	              <h6 className='text'>
	              	Most our projects take 10-20 weeks depending on the number of platforms (web, mobile, etc) and overall functionality. We can also scale up / down to accomodate your timeline.
	              </h6>
	            </PricingCard>
	          </Col>
	          <Col lg={4}>
	            <PricingCard
	              pretitle='Our Rate'
	              title='$125'
	              subtitle='per hour'
	              className='center'>
	              <h6 className='text'>
	              	We typically bill on an hourly basis since it allows for more collaboration and flexibility throughout the project. Don't worry, we always quote the project and are happy to work off a fixed bid if you prefer.
	              </h6>
	            </PricingCard>
	          </Col>
	          <Col lg={4}>
	            <PricingCard
	              pretitle='Typical Billing'
	              title='Bi-Weekly'
	              subtitle='Deposit required'
	              className='right'>
	              <h6 className='text'>
	              	We typically require a 2-week deposit upfront and then bill every other week with Net-14 Terms. That said, we're happy to chat about fixed bid and other options.
	            	</h6>
	            </PricingCard>
	          </Col>
	        </Row>
        </Container>
      </div>
    </Header>
  )
}

export default PricingHeader;
