import React from 'react';
import { Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'src/interface/basics/cards';
import { TextNextButton } from 'src/interface/basics/buttons';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';

const FeatureCard = ({ icon, pretitle, title, subtitle, description, animation, className = '', children }) => {

	return (
		<Card className={`feature-card ${animation} ${className}`}>
			<CardHeader className='feature-card-header'>
				<FadIcon icon={icon} size='2x' className='feature-card-icon'/>
			</CardHeader>
			<CardBody className='feature-card-body'>
				{pretitle && <CardPreTitle>{pretitle}</CardPreTitle>}
				{title && <CardTitle>{title}</CardTitle>}
				{subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
				{description && <CardText>{description}</CardText>}
				{children}
			</CardBody>
		</Card>
	)
}

export default FeatureCard;
