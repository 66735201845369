import React from 'react';
import MktSection from 'src/interface/sections/mkt-section';
import MktAccordion from 'src/interface/accordions/mkt-accordion';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const PricingSectionFaq = ({ className = '' }) => {
  return (
    <MktSection className={`py-8 animate ${className}`}>
      <SectionHeader>
        <Container className='md text-center'>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <SectionText>
            Find answers to our most common questions here.
            For all other questions or to get started, contact us.
          </SectionText>
        </Container>
      </SectionHeader>
      <SectionBody>
        <Container className='pt-5'>
         <MktAccordion id='information' title="Why Mile Hi Labs?" className='mb-4'>
              <h5 className='text'>
                The name Mile Hi Labs represents a fun twist on the nickname of Denver, Colorado where our company is based.
                We also think it represents the pursuit of delivering a high-quality experience and product for our clients.
              </h5>
            </MktAccordion>

            <MktAccordion id='get_started' title="How do I get started?" className='mb-4'>
              <h6 className='text'>
                To get started, simply contact our team with some notes regarding your project and someone will follow up with you to schedule a phone / Video conference.
              </h6>
            </MktAccordion>

            <MktAccordion id='get_started' title="What information should I share?" className='mb-4'>
              <h6 className='text'>
                If you're inquiring about a potential project, please provide a high-level description of the project you're looking to build and where you're at in the process.
                That should be enough for us to get started.
              </h6>
            </MktAccordion>

            <MktAccordion id='information' title="How long will my project take?" className='mb-4'>
              <h6 className='text'>
                Most projects take anywhere between 10-20 weeks to complete from the moment the contract is signed.
                If your project is abnormally large, we'll work with you to break it down into smaller chunks.
                On the flip-side, If your project is too small, it may not fit into our business thesis.
                Either way, we'll give you a more accurate measurement on our call.
              </h6>
            </MktAccordion>

            <MktAccordion id='information' title="How do you handle privacy concerns?" className='mb-4'>
              <h5 className='text'>
                We totally understand and respect the need for NDAs and are happy to sign one if necessary.
                In general, we always aim to keep our conversations and work private unless you've given us express permission to share with others.
              </h5>
            </MktAccordion>

            <MktAccordion id='information' title="How much will my project cost?" className='mb-4'>
              <h5 className='text'>
                Most our projects cost anywhere from $50 - $200k depending on the number of platforms and overall complexity of the project.
                That said, we're happy to take a look at projects bigger or smaller.
              </h5>
            </MktAccordion>

            <MktAccordion id='information' title="What can we expect during the project?" className='mb-4'>
              <h5 className='text'>
                In general, we like to think of ourselves as an extension of your team
                meaning we're happy to provide as much transaparency and collaboration as you need during the project.
                So, we'll work with you during the discovery phase to figure out what works best for you.
              </h5>
            </MktAccordion>

            <MktAccordion id='information' title="What happens when the project is finished?" className='mb-4'>
              <h5 className='text'>
                After the initial project is complete, we'll typically move to a new contract (covering additional features) or a maintenance contract.
                A new contract would behave similar to the previous one whereas a maintenance contract ensures our team is available for system updates, new features, etc on a limited basis.
              </h5>
            </MktAccordion>

            <MktAccordion id='information' title="What guarantees do I have?" className='mb-4'>
              <h5 className='text'>
                We're more happy to stand by our work and guarantee both 99.9% uptime and a bug-free user experience after you take delivery.
                However, please keep in mind software is dynamic by nature meaning some systems may require updates or replacement over time.
                That type of work is not guaranteed since it's outside our control.
              </h5>
            </MktAccordion>
        </Container>
      </SectionBody>
    </MktSection>
  )
}

export default PricingSectionFaq;
