import React, { useState } from 'react';
import { Accordion, AccordionToggle, AccordionCollapse } from 'src/interface/basics/accordions';
import { PrevLink, NextLink, TextLink } from 'src/interface/basics/links';
import { TextButton } from 'src/interface/basics/buttons';

const MktAccordion = ({ id, title, className = '', children }) => {
	const [ show, setShow ] = useState(false);

	return (
		<Accordion className={className}>
			<AccordionToggle id={id} title={title} active={show} onClick={() => setShow(!show)} />
			<AccordionCollapse id={id} className=''>
				{children}
			</AccordionCollapse>
		</Accordion>
	)
};


export default MktAccordion;
